import React, {useState, useEffect} from 'react'

const Pagination = ({showPerPage, onPaginationChange, total}) => {
  

  const [counter, setCounter] = useState(1);
  const [noOfBtn, setNoOfBtn] = useState(Math.ceil(total/showPerPage))

  useEffect(()=>{
    const value = showPerPage*counter;
     onPaginationChange(value - showPerPage, value)
  },[counter]);

  const onBtnClick = (type)=>{
    if(type === 'prev'){
      if(counter === 1){
        setCounter(1)
      }else{
        setCounter(counter-1)
      }
    }else if(type === 'next'){
      if (noOfBtn=== counter){
        setCounter(counter)
      }else{
        setCounter(counter+1)
      }
    }
  }

  return (
    <>
    <div className="d-flex justify-content-end py-2 mt-2">
    <nav aria-label="Page navigation example">
      <ul className="pagination">
        <li className="page-item"><a className="page-link" href="#" onClick={()=>onBtnClick('prev')}>Prev</a></li>
        {new Array(noOfBtn).fill("").map((item, index)=>(
          <li className={`page-item ${index+1 === counter? "active":null}`} key={index}>
            <a className="page-link" href="#" onClick={()=>setCounter(index+1)}>{index+1}</a>
            </li>
        ))}
        <li className="page-item"><a className="page-link" href="#" onClick={()=>onBtnClick('next')}>Next</a></li>
      </ul>
</nav>
      {/* <button className='btn btn-light border mx-2' onClick={()=>onBtnClick('prev')}>Prev</button>
      <button className='btn btn-light border mx-2' onClick={()=>onBtnClick('next')}>Next</button> */}
    </div>

    </>
  )
}

export default Pagination