import React, {useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import BlogCss from './Blog.module.css'
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ErrorPage from '../Error404'

import data from './data';

const BlogFullView = () => {
  const [blogdata, setBloagData] = useState([])


  const { url } = useParams()
  const location = useLocation();
  const navigation = useNavigate();

  useEffect(()=>{
  // setLoading(true)
  const payload = {
    method:"POST",
    headers:{'Content-Type': 'application/json'},
    body:JSON.stringify({"token":"SWA@_SAHRING#_API*_712"})
  }

  fetch('https://swaadhyayan.com/school/appAPI/AppAPIController/getAllBlogPost', payload)
  .then((res)=>res.json())
  .then(data=>{
    console.log(data, 'ehllo')
    if(data.status=="success"){
      setBloagData(JSON.parse(JSON.stringify(data.data)).reverse())
      // setLoading(false)
    }else{
      alert('data not found')
    }
  })
  .catch((err)=>{
    console.log(err)
  })
},[]) 

  // const id = url.split('-').at(-1);
  // const selectData = location.state?.data;

  let selectData = null;

  for (let item of blogdata) {
    if (item.blogUrl == url) {
      selectData = item;
    }
  }

  console.log(JSON.stringify(selectData))
  console.log('jkfjfkjf')




  if (selectData == null) {
    return (
      <ErrorPage />
    )
  }


  return (
    <div >
      <Container className={BlogCss.innerContainer}>
        <Helmet>
          <title>{selectData.blogHeading}</title>
        </Helmet>
        <div className={BlogCss.banner}>
          <img className='img-fluid' src={selectData.blogImageUrl} alt="swaadhyayan" />
        </div>
        <div className={BlogCss.textDiv}>
          <div className={BlogCss.seblogHeading}>{selectData.blogHeading}</div>
          <div className={BlogCss.labelnew}>{selectData.blogPublishDate}, By {selectData.blogPostedBy}</div>
          <div className={BlogCss.msgText}
           dangerouslySetInnerHTML={{__html: selectData.blogDescription}}/>
            <div className={BlogCss.msgText}
            dangerouslySetInnerHTML={{__html: selectData.blogContent}}/>
          </div>
        <div className={BlogCss.btnDiv}>
          <button className='btn btn-secondary' onClick={() => { navigation(-1) }}>Go back</button>
        </div>

      </Container>



      {/* {selectData == null &&
                  <ErrorPage/>
                  } */}

    </div>

  )
}

export default BlogFullView